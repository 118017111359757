<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <div class="profile-tab-container">
                        <div class="profile-intro">
                            <h2>Selvesan Malakar </h2>
                            <h4>Grade 10 / Section A</h4>
                        </div>
                        <div class="tab-content">
                            <div class="t-container">
                                <div class="profile-image">
                                    <div class="image">
                                        <img src="https://cdn.vuetifyjs.com/images/john.jpg"
                                             alt="John">
                                    </div>
                                </div>

                                <div class="t-hold">
                                    <v-bottom-nav
                                            :value="true"
                                            absolute
                                            color="transparent">
                                        <v-btn color="teal"
                                               flat
                                               value="recent">
                                            <span>Generals</span>
                                            <v-icon>history</v-icon>
                                        </v-btn>

                                        <v-btn color="teal"
                                               flat
                                               value="favorites">
                                            <span>Enroll</span>
                                            <v-icon>favorite</v-icon>
                                        </v-btn>

                                        <v-btn color="teal"
                                               flat
                                               value="nearby">
                                            <span>Transportation</span>
                                            <v-icon>directions_bus</v-icon>
                                        </v-btn>
                                    </v-bottom-nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-flex>

            <div class="profile-contents">
                <v-layout row wrap>
                    <v-flex>
                        <v-flex xs12 sm4>
                            <v-card class="mb-2 elevation-5">
                                <v-list>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="primary">person</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right">{{ student.name }}
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider></v-divider>
                                    <v-list-tile v-if="student.dob">
                                        <v-list-tile-action>
                                            <v-icon color="primary">cake</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right">{{ student.dob }}
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile v-if="student.email">
                                        <v-list-tile-action>
                                            <v-icon color="primary">fa-at</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right">{{ student.email }}
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile v-if="student.gender">
                                        <v-list-tile-action>
                                            <v-icon color="primary">{{ student.gender === 'male' ? 'fa-male' :
                                                'fa-female' }}
                                            </v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right"></v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="primary">business</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right">
                                            </v-list-tile-title>
                                            <v-list-tile-sub-title class="text-xs-right"></v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="yellow darken-2">star</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right">
                                                4.5 ( 10 reviews )
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="primary darken-2">message</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right primary--text">
                                                SMS Guardian
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="success darken-2">plus_one</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right success--text">
                                                Promote
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider/>
                                    <v-list-tile>
                                        <v-list-tile-action>
                                            <v-icon color="primary">directions_bus</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <template v-if="transport">
                                                <v-list-tile-title class="text-xs-right">
                                                    {{ transport.destination }}
                                                </v-list-tile-title>
                                                <v-list-tile-sub-title class="text-xs-right">{{ transport.route }},
                                                    {{
                                                    transport.vehicle }}
                                                </v-list-tile-sub-title>
                                            </template>
                                            <template v-else>
                                                <v-list-tile-title class="text-xs-right primary--text">Manage
                                                    Transport
                                                </v-list-tile-title>
                                            </template>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <!--<v-list-tile @click="warningDialog = true">
                                        <v-list-tile-action>
                                            <v-icon color="error darken-2">warning</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title class="text-xs-right error&#45;&#45;text">
                                                Delete
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>-->
                                </v-list>
                            </v-card>
                        </v-flex>
                    </v-flex>
                </v-layout>
            </div>
        </v-layout>

        <!--<v-layout>-->
        <!---->

        <!--</v-layout>-->
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({}, '/api/student'),
            student: {}
        }),
        watch: {},
        mounted() {
        },
        computed: {},
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            }
        }
    }
</script>

<style lang="scss">
    .user_add_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }

    .profile-contents {
        width: 85%;
        margin: auto;
    }

    .container {
        padding: 0 !important;
        .profile-tab-container {
            min-height: 180px;
            /*background: linear-gradient(left, #18e0f5, #135aa5 80%);*/
            position: relative;
            display: flex;
            .profile-intro {
                position: absolute;
                bottom: 65px;
                left: 250px;
                h2 {
                    font-weight: bold;
                    color: white;
                }
                h4 {
                    color: white;
                    line-height: 10px;
                }
            }
            .tab-content {
                align-self: flex-end;
                background-color: white;
                height: 55px;
                width: 100%;
                .t-container {
                    height: inherit;
                    width: 85%;
                    margin: auto;
                    display: flex;
                    .t-hold {
                        flex: 3;
                        /*background-color: red;*/
                    }
                    .profile-image {
                        flex: 1;
                        position: relative;
                        .image {
                            height: 120px;
                            width: 120px;
                            position: absolute;
                            /*background-color: red;*/
                            left: 30px;
                            border-radius: 120px;
                            border: 5px solid white;
                            top: -70px;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                        /*background-color: green;*/
                    }
                }
            }
        }
    }

    .v-item-group.v-bottom-nav {
        /*right: 80px;*/
        /*justify-content: right;*/
    }

    .v-item-group.v-bottom-nav {
        box-shadow: none;
    }
</style>